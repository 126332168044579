#mobileContact {
  background-color: #fff5f8;
}

#mobileContact form {

  margin: 0 auto;
}

#mobileContact label {
  letter-spacing: 1px;
  font-weight: 500;
  font-size: 0.85rem;
}

#mobileContact input {
  border: 2px solid #E0DFFD;
  border-radius: 3px;
  margin: 10px 0;
  width: 100%;
  height: 30px;
  font-size: 1rem;
}


#mobileContact input[type='text']:focus, #mobileContact input[type='email']:focus, #mobileContact textarea[type='text']:focus {
  border-style: solid;
  outline-color: #7766C6;
  border-width: 2px;
}

#mobileContact .btnDiv {
  margin-top: 15px;
  text-align: center;
}

#mobileContact .inputDiv {
  margin: 15px 10px;
}

.message textarea {
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  border: 2px solid #E0DFFD;
  display: block;
  resize: none;
  width: 100%;
  height: 100px;
  margin-top: 10px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr 1.5fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.grid .name {
  grid-area: 1 / 1 / 2 / 2;
}

.grid .email {
  grid-area: 1 / 2 / 2 / 3;
}

.grid .message {
  margin-top: 0;
  grid-area: 2 / 1 / 3 / 3;
}

.backdrop {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  position: fixed;
  width: 100%;
  height: 93.2%;
  left: 0;
  background: rgba(245, 245, 247);
  pointer-events: none;
  /* display: none; */
  pointer-events: all;

  animation: fade-in 0.8s forwards;
}

@keyframes fade-in {
  0% { opacity: 0%; }
  100% { opacity: 83%; }
}

/***************************************************/
/****************** MEDIA QUERIES ******************/
/* Laptop */
/* @media only screen and (max-width: 1440px)  {
  #mobileContact {
    height: 60%;
    top: 17%;
  }
} */