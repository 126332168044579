#contactForm form {
  padding: 0 25px 25px;
  margin: 0 auto;
}

#contactForm label {
  letter-spacing: 1px;
  font-weight: 500;
  font-size: 0.85rem;
}

#contactForm input {
  border: 2px solid #E0DFFD;
  border-radius: 3px;
  margin: 10px 0;
  width: 100%;
  height: 30px;
  font-size: 1rem;
}


#contactForm input[type='text']:focus, #contactForm input[type='email']:focus, #contactForm textarea[type='text']:focus {
  border-style: solid;
  outline-color: #7766C6;
  border-width: 2px;
}

#contactForm .btnDiv {
  margin-top: 15px;
  text-align: center;
}

#contactForm .inputDiv {
  margin: 15px 10px;
}

.message textarea {
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  border: 2px solid #E0DFFD;
  display: block;
  resize: none;
  width: 100%;
  height: 100px;
  margin-top: 10px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr 1.5fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.grid .name {
  grid-area: 1 / 1 / 2 / 2;
}

.grid .email {
  grid-area: 1 / 2 / 2 / 3;
}

.grid .message {
  margin-top: 0;
  grid-area: 2 / 1 / 3 / 3;
}
