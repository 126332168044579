#mobileSkills {
  transition: 2s;
}

#mobileSkills .skill {
  width: 100%;
  margin: 25px 0;
}

#mobileSkills ul {
  padding-left: 0;
}


#mobileSkills li {
  list-style-type: none;
  padding: 3px 0;
  font-family: 'Roboto Mono', monospace;
}

