#mobileNavMenu {
  position: absolute;
  z-index: 3;
  width: 100%;
}

.menuIcon {
  text-align: right;
  padding: 15px 20px 0 0;
}

.menuIcon span:hover {
  color: #7766c6;
}

.mobileNavItems {
  background-color: #e7e4f6;
}

.mobileNavItems ul {
  padding: 0;
}

.mobileNavItems li {
  font-family: 'Roboto', sans-serif;
  color: #46467a;
  width: 100%;
  list-style-type: none;
  margin-left: 0;
  letter-spacing: 1.5px;
  border-bottom: 1px solid white;
  text-decoration: none;
}

.mobileNavItems li:hover {
  background-color: #f5f3ffc8;
}

.crossIcon {
  text-align: right;
  padding: 15px 20px 15px 30px;
}

.mobileNavItems .crossIcon:hover {
  background-color: #e7e4f6;
}

.mobileNavItems span:hover {
  background-color: #f5f3ffc8;
  border-radius: 100%;
}

.backdrop {
  position: absolute;
  background-color: rgba(27, 27, 27, 0.695);
  height: 100vh;
  width: 100vw;
}

.mobileNavItems .listText {
  color: #46467A;
  display: inline-block;
  text-decoration: none;
  padding: 16px 20px 16px 30px;
  width: 500px;
}
