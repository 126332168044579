#projectsDirWindow {
  height: 100vh;
  width: 100%;
  top: 0%;
  left: 0%;
}

.projectsDir {
  height: 100%;
}

.grid {
  height: 100%;
  display: grid;
  grid-template-columns: 0.3fr 1fr;
  grid-template-rows: 1fr;
}

.sidebarDiv {
  background-color: #ebeaf8;
  border-right: 1px solid #46467a;
  border-bottom-left-radius: 10px;
  grid-area: 1 / 1 / 2 / 2;
  height: 99.5%;
}

.sidebarDiv ul {
  padding-left: 0;
}

.sidebarDiv li {
  padding: 15px 25px;
  border-bottom: 1px solid #46467a;
  list-style-type: none;
  margin-left: 0;
  letter-spacing: 1.5px;
}

.sidebarDiv li:hover {
  background-color: #867ec4;
  color: white;
}

.projectContent {
  grid-area: 1 / 2 / 2 / 3;
  height: 100%;
}

.projectIcons {
  margin-top: 45px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

/* ProjectDir <Window> component's content */
.actionBar {
  height: 4.5%;
}

.content {
  height: 96%;
}
