* {
  box-sizing: border-box;
  margin: 0px;
}

body {
  background-color: #9c9cb7;
  color: #46467a;
  height: 100vh;
  overflow: hidden;
  font-family: 'Roboto', sans-serif;
  overflow-y: scroll;
}

h3 {
  text-align: center;
  margin: 20px 0;
  padding: 10px;
  background-color: #e0dffd7e;
  font-size: 0.9rem;
  letter-spacing: 4px;
}

.padding {
  padding: 70px;
}

@media only screen and (min-width: 320px) and (max-width: 640px) {
  .padding {
    padding: 55px 26px;
  }
}
