.desktopIcon {
  text-align: center;
  margin: 0 20px 15px;
  padding: 10px 13px;
  border-radius: 5%;
}


.desktopIcon:hover {
  background-color: #ccaeff60;
}

.icon {
  width: 60px;
}


.iconName {
  font-size: 0.9rem;
  letter-spacing: 0.8px;
  padding-top: 4px;
  font-weight: 500;
}
