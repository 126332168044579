#landingPage {
  position: relative;
}


.active {
  z-index: 100;
}

.inActive {
  z-index: 1;
}