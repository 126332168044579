.leftDesktopIcons {
  position: absolute;
  z-index: 1;
}

.leftDesktopIcons a {
  color: #46467a;
  text-decoration: none;
  cursor: auto;
}

.rightDesktopIcons {
  position: absolute;
  z-index: 1;
  right: 0;
}