.dockbarIcon {
  display: inline-block;
  text-align: center;
  height: 40px;
  margin: 15px;
}

.icon {
  width: 40px;
  transform-origin: 50% 100%;
  transition: all 0.2s;
}

.icon:hover {
  transform: scale(2);
  margin: 0 2.25em;
}

.iconName {
  letter-spacing: 0.8px;
  font-size: 1rem;
  display: none;
  position: relative;
  bottom: 120px;
  left: 0;
  background-color: rgba(235, 224, 240, 0.661);
  padding: 4px 0;
  border-radius: 12px;
}

.dockbarIcon:hover .iconName {
  display: block;
}