.window {
  background: white;
  box-shadow: 10px 10px 1px 0px rgb(0 0 0 / 26%);
  border-radius: 10px;
  /* z-index: 1; */
  position: absolute;
  border: 2px solid #46467a;
  width: 45%;
  height: 45%;
  top: 25%;
  animation: fadeIn 0.1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.actionBar {
  background-color: #7766c6;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border-bottom: 2px solid #46467a;
  height: 10%;
}

.circleFill {
  border-radius: 100%;
  font-size: 0.8rem;
  margin: 11px 3px;
  border: 1px solid #46467a;
}

.red {
  color: rgb(255, 95, 87);
  margin-left: 10px;
}

.red:hover {
  color: rgb(251, 127, 121);
}

.amber {
  color: rgb(255, 188, 46);
}

.amber:hover {
  color: rgb(255, 205, 98);
}

.green {
  color: rgb(40, 200, 65);
}

.green:hover {
  color: rgb(91, 214, 109);
}


.content {
  overflow-y: scroll;
  border-bottom-left-radius: 2%;
  border-bottom-right-radius: 2%;
  height: 90%;
}

/***************************************************/
/****************** MEDIA QUERIES ******************/
/* Laptop */
@media only screen and (max-width: 1440px) {
  .circleFill {
    margin: 8px 3px;
  }

  .red {
    margin-left: 10px;
  }
}
