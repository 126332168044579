.button74 {
  background-color: #7766c6;
  border: 2px solid #46467a;
  border-radius: 30px;
  box-shadow: #46467a 4px 4px 0 0;
  color: white;
  cursor: pointer;
  display: block;
  font-family: 'Roboto Mono', monospace;
  font-weight: 600;
  font-size: 18px;
  margin: 0 auto;
  padding: 0 20px;
  letter-spacing: 2px;
  line-height: 35px;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button74:hover {
  background-color: #cec5f5;
  color: #46467a;
}

.button74:active {
  box-shadow: #183163 2px 2px 0 0;
  transform: translate(2px, 2px);
}

@media (min-width: 768px) {
  .button74 {
    min-width: 120px;
    padding: 0 25px;
  }
}