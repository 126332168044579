.dockbar {
  background-color: #ffffff8c;
  border-radius: 25px;
  position: absolute;
  display: flex;
  z-index: 1;
  width: 270px;
  height: 70px;
  bottom: 1.5%;
  text-align: center;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 10px 0;
  align-items: center;
  justify-content: center;
}
