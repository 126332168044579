#mobileSummary {
  color: white;
  font-family: 'Roboto Mono', monospace;
}

#mobileSummary .summaryText {
  font-size: 1.2rem;
  line-height: 32px;
  text-align: center;
  padding: 90px 70px;
  color: white;
}

#mobileSummary .link {
  display: block;
  margin-top: 30px;
  text-decoration: none;
  color: #46467a;
  font-size: 1rem;
  letter-spacing: 1px;
}

#mobileSummary .link:hover {
  text-decoration: underline;
}

/***************************************************/
/****************** MEDIA QUERIES ******************/
/* Mobile */
@media only screen and (min-width: 320px) and (max-width: 499px) {
  #mobileSummary .summaryText {
    padding: 55px 26px;
  }
}
