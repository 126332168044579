#accordionDiv .accordion {
  margin: 20px auto;
  padding: 15px 40px;
  color: #46467a;
}

#accordionDiv .summaryContent {
  display: flex;
  width: 100%;
  margin: 0 auto;
}

#accordionDiv .summaryList {
  column-count: 2;
  column-gap: 70px;
  padding: 15px 25px 15px 60px;
  width: 100%;
}

#accordionDiv .details {
  margin-top: 10px;
  letter-spacing: 0.5px;
  line-height: 30px;
  font-size: 1.1rem;
}

#accordionDiv img {
  align-self: center;
}

@media only screen and (min-width: 500px) and (max-width: 640px) {
  #accordionDiv .summaryList {
    padding-left: 30px;
  }
}

@media only screen and (max-width: 499px) {
  #accordionDiv .accordion {
    padding: 15px;
  }
  
  #accordionDiv .accordionSummary {
    flex-direction: column;
  }

  #accordionDiv .summaryList {
    padding: 15px;
  }

  #accordionDiv img {
    width: 60px;
    margin-bottom: 5px;
    align-self: center;
  }

  #accordionDiv .summaryContent {
    display: flex;
    flex-direction: column;
  }
}
