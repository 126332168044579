#creditsWindow {
  height: 60%;
  width: 35%;
  top: 17%;
  left: 32.6%;
}

#creditsWindow .credits {
  height: 100%;
}

#creditsWindow .creditsContent {
  padding-bottom: 5px;
}

#creditsWindow .creditsContent a {
  color: #46467a;
  font-size: 1.3rem;
  display: block;
  text-decoration: none;
  letter-spacing: 1px;
  margin: 30px 0;
  text-align: center;
}

#creditsWindow .creditsContent a:hover {
  color: #a580e4;
}

/* Credits <Window> component's content */
.actionBar {
  height: 7.5%;
}

.content {
  height: 92.5%;
}