#contactMe {
  width: 38%;
  height: fit-content;
  left: 31%;
  top: 15%;
  overflow: hidden;
}

#contactMe .form{
  margin-bottom: 15px;
}

/***************************************************/
/****************** MEDIA QUERIES ******************/
/* Laptop */
@media only screen and (max-width: 1440px)  {
  #contactMeForm {
    height: 60%;
    top: 17%;
  }
}