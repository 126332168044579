#mobileAboutMe .aboutMe {
  position: absolute;
  z-index: 1;
  width: 100%;

}

#mobileAboutMe .bg {
  height: 600px;
}

#mobileAboutMe .header {
  width: 515px;
  margin: 150px auto 0;
}

#mobileAboutMe h2 {
  font-size: 4.5rem;
  font-family: 'Roboto Mono', monospace;
}

#mobileAboutMe h4 {
  font-size: 2.5rem;
  font-family: 'Roboto Mono', monospace;
  font-weight: 300;
}

#mobileAboutMe a {
  text-decoration: none;
}

#mobileAboutMe .button {
  margin-top: 35px;
}

@media (min-width: 768px) {
  .button74 {
    min-width: 120px;
    padding: 0 25px;
  }
}

.arrowIcon {
  margin: 0 auto;
}

/***************************************************/
/****************** MEDIA QUERIES ******************/

/* Mobile */
@media only screen and (max-width: 550px) {
  #mobileAboutMe .aboutMe {
    padding: 0 26px;
  }

  #mobileAboutMe .header {
    width: 100%;
  }

  #mobileAboutMe .bg {
    height: 600px;
  }
}

@media only screen and (min-width: 453px) and (max-width: 499px) {
  #mobileAboutMe h2 {
    font-size: 4rem;
  }
}

@media only screen and (min-width: 320px) and (max-width: 452px) {
  #mobileAboutMe h2 {
    font-size: 3rem;
  }

  #mobileAboutMe h4 {
    font-size: 1.75rem;
  }
}
