#sidebar1,
#sidebar2,
#sidebar3,
#sidebar4 {
  top: 18px;
  width: 28rem;
  height: 96%;
  border-bottom-left-radius: 8px;
}

#sidebar1 p,
#sidebar2 p,
#sidebar3 p,
#sidebar4 p {
  font-family: 'Roboto', sans-serif;
  color: #46467a;
  margin-top: 20px;
  letter-spacing: 1px;
  line-height: 28px;
}

#sidebar1 li,
#sidebar2 li,
#sidebar3 li,
#sidebar4 li {
  font-family: 'Roboto', sans-serif;
  color: #46467a;
  padding: 15px 0;
  list-style-type: none;
  margin-left: 0;
  letter-spacing: 1.5px;
}

#sidebar1 h3,
#sidebar2 h3,
#sidebar3 h3,
#sidebar4 h3 {
  margin-top: 0;
}

/***************************************************/
/****************** MEDIA QUERIES ******************/
/* Laptop */
@media only screen and (max-width: 1440px) {
  #sidebar1,
  #sidebar2,
  #sidebar3,
  #sidebar4 {
    top: 19px;
    height: 95.1%;
    border-bottom-left-radius: 6px;
    width: 23rem;
  }
}
