#tiles {
  width: calc(100vw - 1px);
  position: relative;
  z-index: 0;
  display: grid;
  grid-template-columns: repeat(var(--columns), 1fr);
  grid-template-rows: repeat(var(--rows), 1fr);
}

.tilesHeight {
  height: calc(100vh - 1px);
}

.tile {
  /* cursor: pointer; */
  position: relative;
  background-color: rgba(0, 0, 0, 0);

}

.tile:before {
  background-color: #fff5f8;
  content: '';
  inset: 0.5px;
  position: absolute;
}