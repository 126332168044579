#mobileCard {
  background-color: rgb(255, 255, 255);
  box-shadow: 10px 10px 1px 0px rgb(0 0 0 / 26%);
  border: 2px solid #46467a;
  border-radius: 10px;
  padding: 30px 60px;
}

@media only screen and (max-width: 550px) {
  #mobileCard {
    width: 100%;
    padding: 20px 25px;
  }
}
