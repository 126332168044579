#aboutMeWindoow {
  left: 27.3%;
}

#aboutMe {
  font-family: 'Roboto Mono', monospace;
  height: 100%;
  overflow-y: scroll;
}

.aboutMeHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 86%;
}

.aboutMeContent {
  margin-top: 10%;
  width: 85%;
  margin: auto;
  scroll-behavior: smooth;
  padding-bottom: 10px;
}

.typewriter h2 {
  font-size: 4.3rem;
  overflow: hidden;
  border-right: 0.07em solid #46467a;
  white-space: nowrap;
  margin: 0 auto;
  animation: typing 2s steps(13, end), blink-caret 0.75s step-end infinite;
}

.bounceArrow {
  color: #cccce5;
  font-weight: 600;
  font-size: 2rem;
  margin: 0 auto;
  position: relative;
  left: 50%;
}

.bounce {
  animation: bounce 2s ease infinite;
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: #46467a;
  }
}

/* Arrow bouncing effect */
@keyframes bounce {
  70% {
    transform: translateY(0%);
  }
  80% {
    transform: translateY(-15%);
  }
  90% {
    transform: translateY(0%);
  }
  95% {
    transform: translateY(-7%);
  }
  97% {
    transform: translateY(0%);
  }
  99% {
    transform: translateY(-3%);
  }
  100% {
    transform: translateY(0);
  }
}
