#creditsContent .creditsContent a {
  color: #46467a;
  font-size: 1.3rem;
  display: block;
  text-decoration: none;
  letter-spacing: 1px;
  margin: 30px 0;
  text-align: center;
}

#creditsContent .creditsContent a:hover {
  color: #a580e4;
}