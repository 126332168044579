.swiper {
  text-align: center;
  width: 1100px;
  margin: 50px auto 0;
}

.swiper img {
  width: 100%;
}

.swiper p {
  margin: 20px;
}

.defaultSlide {
  margin: 0 auto;
  border: 3px solid #E0DFFD;
  width: 90%;
  height: 482px;
}

.textBox {
  margin-top: 200px;
}

/***************************************************/
/****************** MEDIA QUERIES ******************/
/* Laptop */
@media only screen and (max-width: 1440px)  {
  .swiper {
    width: 942px;
  }
}

