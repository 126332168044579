.bg {
  height: 500px;
}

/***************************************************/
/****************** MEDIA QUERIES ******************/
/* Mobile */
@media only screen and (min-width: 320px) and (max-width: 640px) {
  .padding {
    padding: 55px 26px;
  }
}
